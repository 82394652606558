import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ){ }

  logout(): void {
    this.clearStorage();
    this.router.navigate(['/auth'], { queryParams: { returnUrl: this.router.url }});
  }

  clearStorage(): void {
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('SesToken') != null;
  }

  setStorage(valueUser){
    
    let sesLocalUser = {
      "user": valueUser['dataAdmin'],
      "detail":valueUser['dataAdminDetail']
    }

    localStorage.setItem('SesUser', JSON.stringify(sesLocalUser));
  }

  login(alias: string , password: string){
    
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    let data = {
      "alias": alias,
      "password": password,
      "type": "User_Backend",
      "token_firebase":""
    }

    return this.http.post<any>(`${API_URL}login`, 
      data, { headers: reqHeader }
    ).pipe(map(res => {
      
      if(res.code == 200){
        localStorage.setItem('SesToken', JSON.stringify(res));
        return alias
      }

    }))
    
  } 

}
